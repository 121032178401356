<template>
  <h2 v-if="app">Edit - {{ app.name }}</h2>
  <div class="container">
    <form @submit.prevent="submit">
      <div class="input-container">
        <label>App-Name: </label>
        <input v-model="form.name" type="text" />
      </div>
      <small v-if="errors.name">{{ errors.name[0].message }}</small>
      <div class="input-container">
        <label>Server-Host: </label>
        <input v-model="form.server_host" type="text" />
      </div>
      <small v-if="errors.server_host">{{
        errors.server_host[0].message
      }}</small>
      <div class="input-container">
        <label>Auth-Path: </label>
        <input v-model="form.auth_path" type="text" />
      </div>
      <small v-if="errors.auth_path">{{ errors.auth_path[0].message }}</small>
      <div class="input-container">
        <label>Server-Token: </label>
        <input v-model="form.server_token" type="text" />
      </div>
      <small v-if="errors.server_token">{{
        errors.server_token[0].message
      }}</small>
      <div class="input-container">
        <label>Client-Host: </label>
        <input v-model="form.client_host" type="text" />
      </div>
      <small v-if="errors.client_host">{{
        errors.client_host[0].message
      }}</small>

      <button type="submit">Update</button>
    </form>
  </div>

  <router-view />
</template>

<script>
import FormMixin from "../mixins/FormMixin";
export default {
  name: "EditComp",
  mixins: [FormMixin],
  created() {
    this.setForm({
      name: ["required"],
      server_host: ["required"],
      auth_path: ["required"],
      server_token: [],
      client_host: ["required"],
    });
  },
  computed: {
    app() {
      let app = this.$store.getters["apps/app"](this.$route.params.name);
      app = { ...app };
      this.setFormData(app);
      return app;
    },
  },
  mounted() {
    this.setFormData();
  },
  methods: {
    setFormData(app = this.app) {
      console.log("setFormData", app);
      this.form = { ...app };
      this.form.server_token = "";
    },
    async submit() {
      await this.validate();
      console.log(this.formValid);
      console.log(this.errors);
      if (this.formValid) {
        await this.$store.dispatch("apps/updateApp", {
          app: this.form,
          name: this.$route.params.name,
        });
        this.$router.push(`/app/${this.form.name}/edit`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .input-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 6px;
      margin-bottom: 6px;
      label {
        margin-inline-end: 10px;
      }
    }
  }
}
</style>
